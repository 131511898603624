import {projectCode} from "./constants";

const CryptoJS = require("crypto-js");

export const status = "Live";
export const serverLink =
    status === "Dev"
        ?
         "http://localhost:4480/"
        : "https://teamcoded.com.ng/odfel-server/";

export const projectURL = "https://kadpolyodfel.ng/";
export const projectPhone = " +234-8036424139, +234-8098909053, +234-8180599505";
export const projectEmail = "odfel@kadunapoly.edu.ng";

//LIVE REMITA KEY
export const MERCHANTID = "542778173"
export const SERVICETYPEID = "583858080"
export const APIKEY = "286513"
export const GATEWAYURL = "https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit"
export const GATEWAYRRRPAYMENTURL = "https://login.remita.net/remita/ecomm/finalize.reg"
export const CHECKSTATUSURL = "https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc"
export const URL_REDIRECT = window.location.host+"/hr/finance/remita";
export const paymentAPIKey = status === "Dev" ? "pk_test_7190e88e0943b595e1e0dc3bc71bd30930c10f98" :  "pk_live_a96397ff08ed72eb9f7c60cac6f7ddf5b4c9f29a" ;


// //TESTING REMITA KEY
// export const MERCHANTID = "2547916"
// export const SERVICETYPEID = "4430731"
// export const APIKEY = "1946"
// export const GATEWAYURL = "https://remitademo.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit"
// export const GATEWAYRRRPAYMENTURL = "https://remitademo.net/remita/ecomm/finalize.reg"
// export const CHECKSTATUSURL = "https://remitademo.net/remita/ecomm"
// export const URL_REDIRECT = window.location.host+"/admission/check-admission-status";

export function encryptData(string, val = false) {
    let secret_key = val === false ? 'ODFel' : projectCode;
    let secret_iv = val === false ? 'ODFel' : projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

    let result = decrypted.toString();
    return btoa(result);
}

export function decryptData(string, val = false) {
    let secret_key = val === false ? 'ODFel' : projectCode;
    let secret_iv = val === false ? 'ODFel' : projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

    var result = decrypted.toString(CryptoJS.enc.Utf8);
    return result;
}